<template>
  <div class="pdf-player bg-primary-900 ar-16_9 w-full flex relative">
    <div class="background-full bg-contain bg-no-repeat bg-center absolute top-0 left-0 right-0 bottom-0 opacity-50"
         :style="`background-image: url(${module.thumbnail})`" v-if="module && module.thumbnail"></div>
    <div class="w-fit m-auto flex flex-col items-center cursor-pointer relative z-10" @click="openPdf">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="103.318" height="94.297" viewBox="0 0 103.318 94.297">
        <defs>
          <filter id="Path_206" x="0" y="56.107" width="103.318" height="38.19" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="blur"/>
            <feFlood flood-opacity="0.161"/>
            <feComposite operator="in" in2="blur"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Path_207" x="40.395" y="0" width="22" height="72.222" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="blur-2"/>
            <feFlood flood-opacity="0.161"/>
            <feComposite operator="in" in2="blur-2"/>
            <feComposite in="SourceGraphic"/>
          </filter>
          <filter id="Path_208" x="22.671" y="36.205" width="57.447" height="39.723" filterUnits="userSpaceOnUse">
            <feOffset dy="3" input="SourceAlpha"/>
            <feGaussianBlur stdDeviation="3" result="blur-3"/>
            <feFlood flood-opacity="0.161"/>
            <feComposite operator="in" in2="blur-3"/>
            <feComposite in="SourceGraphic"/>
          </filter>
        </defs>
        <g transform="matrix(1, 0, 0, 1, 0, 0)" filter="url(#Path_206)">
          <path id="Path_206-2" data-name="Path 206" d="M3726,5799v16.19h81.318V5799" transform="translate(-3715 -5734.89)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
        </g>
        <g id="Group_200" data-name="Group 200" transform="translate(-662.705 -389.893)">
          <g transform="matrix(1, 0, 0, 1, 662.7, 389.89)" filter="url(#Path_207)">
            <path id="Path_207-2" data-name="Path 207" d="M3779.1,5739.893v50.222" transform="translate(-3727.7 -5731.89)" fill="none" stroke="#fff" stroke-linecap="round" stroke-width="4"/>
          </g>
          <g transform="matrix(1, 0, 0, 1, 662.7, 389.89)" filter="url(#Path_208)">
            <path id="Path_208-2" data-name="Path 208" d="M0,16.9,16.9,0l16.9,16.9" transform="translate(68.29 61.93) rotate(180)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="4"/>
          </g>
        </g>
      </svg>
      <div class="text-center text-xl">Télécharger</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'pdf-player',
  data() {
    return {
      module: null,
    };
  },
  computed: {},
  methods: {
    setSource(module) {
      this.module = module;
      this.initialize();
      this.$emit('progress', { ended: true });
    },
    initialize() {
      return true;
    },
    openPdf() {
      window.open(this.module.filePath, '_blank');
    },
  },
})
export default class PdfPlayer extends Vue {
}
</script>
