<template>
  <div class="preview-container bg-primary-900 w-full ar-16_9" :class="{ hidden: isFolder }">
    <plyr-player ref="player"
                 @progress="handleVideoProgress"
                 @chapter-change="handleChapterChange"
                 v-if="isPlayerVideo"
                 :key="videoId"></plyr-player>
    <quiz-player ref="quiz" @progress="handleQuizProgress" v-if="isQuiz" @quiz-end="handleQuizEnd" :key="module.id"></quiz-player>
    <pdf-player ref="pdf" @progress="handlePdfProgress" v-if="isPDF" :key="module.id"></pdf-player>
    <audio-player ref="audio" @progress="handleAudioProgress" v-if="isAudio" :key="module.id"></audio-player>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { mapState } from 'vuex';
import uniqueId from '../../../_helpers/generator.helper';
import PdfPlayer from '../../../components/player/pdf-player.vue';
import QuizPlayer from '../../../components/player/quiz-player.vue';
import PlyrPlayer from '@/components/player/plyr-player.vue';
import AudioPlayer from '../../../components/player/audio-plyr-player.vue';

@Component({
  name: 'module-preview',
  components: {
    AudioPlayer,
    PdfPlayer,
    QuizPlayer,
    PlyrPlayer,
  },
  data() {
    return {
      videoId: uniqueId(),
      previousModule: null,
    };
  },
  watch: {
    module: {
      handler(value) {
        this.handleModuleChange(value);
        this.previousModule = value;
      },
      deep: true,
    },
  },
  beforeDestroy() {
    this.handleQuitingVideoPlayer();
    window.removeEventListener('beforeunload', this.handleUnload);
  },
  methods: {
    handleUnload(evt) {
      // Cancel the event (if necessary)
      evt.preventDefault();
      this.handleQuitingVideoPlayer();
      // Google Chrome requires returnValue to be set
      // eslint-disable-next-line no-param-reassign
      evt.returnValue = '';
      return null;
    },
    getChildChapterById(id) {
      // eslint-disable-next-line no-restricted-syntax
      for (const child of this.module.children) {
        if (child.id === id) return child;
      }
      return null;
    },
    handleChapterChange({ chapter }) {
      // eslint-disable-next-line no-underscore-dangle
      const _chapter = this.getChildChapterById(chapter.index);
      if (!_chapter) {
        console.error('chapter not found', chapter);
        return;
      }
      this.$store.commit('auth/module/SET_CURRENT_CHAPTER', _chapter);
    },
    handleQuizEnd() {
      this.$store.commit('auth/module/CHECK_CURRENT_RESOURCE');
    },
    handleQuitingVideoPlayer() {
      if (this.previousModule && this.previousModule.type === 'VIDEO') {
        const { player } = this.$refs;
        if (player) {
          if (!this.previousModule.meta) this.previousModule.meta = {};
          if (!this.previousModule.meta.video) this.previousModule.meta.video = {};
          this.previousModule.meta.video.time = player.getPlayerTime();
          this.$store.dispatch('auth/module/updateProgress', {
            id: this.previousModule.moduleId,
            srcGroupId: this.previousModule.srcGroupId,
            type: this.previousModule.type,
            resourceId: this.previousModule.id,
            progress: {
              time: this.previousModule.meta.video.time,
            },
          });
        }
      }
    },
    handleModuleChange(value, isUserBarInteraction = false) {
      const { player } = this.$refs;
      if (player && value && this.isPlayerVideo && this.module.child) {
        const previousChild = this.previousModule && this.previousModule.child ? this.previousModule.child : null;
        if (this.module.child.userInteraction === true || (previousChild && previousChild.id !== this.module.child.id)) {
          this.module.child.userInteraction = false;
          this.$nextTick(() => {
            player.setChapterId(this.module.child.setChapter);
          });
        }
      }
      if (this.previousModule && this.previousModule.id === this.module.id) {
        return;
      }
      this.handleQuitingVideoPlayer();
      this.$nextTick(() => {
        this.initVideoPlayer();
        this.initQuizPlayer();
        this.initPdfPlayer();
        this.initFolderPlayer();
        this.initAudioPlayer();
      });
    },
    initFolderPlayer() {
      if (!this.isFolder) return;
      this.$store.dispatch('auth/module/updateProgress', {
        id: this.module.moduleId,
        srcGroupId: this.module.srcGroupId,
        type: this.module.type,
        resourceId: this.module.id,
        progress: {
          ended: true,
        },
      });
    },
    initPdfPlayer() {
      if (!this.isPDF) return;
      const { pdf } = this.$refs;
      if (!pdf) {
        console.error('pdf player not found');
        return;
      }
      pdf.setSource(this.module);
    },
    initAudioPlayer() {
      if (!this.isAudio) return;
      const { audio } = this.$refs;
      if (!audio) {
        console.error('audio player not found');
        return;
      }
      audio.setSource(this.module);
      audio.setModule(this.module);
      audio.initialize();
    },
    initQuizPlayer() {
      if (!this.isQuiz) return;
      const quizPlauer = this.$refs.quiz;
      if (!quizPlauer) {
        return;
      }
      quizPlauer.setSource(this.module);
    },
    initVideoPlayer() {
      if (!this.isPlayerVideo) return;
      // eslint-disable-next-line prefer-destructuring
      const player = this.$refs.player;
      if (!player) {
        return;
      }
      player.setSource(this.module.source);
      player.setModule(this.module);
      if (this.module.child) {
        player.setChapterId(this.module.child.setChapter);
      }
      player.initialize();
    },
    handleVideoProgress(progress) {
      return progress;
    },
    handleAudioProgress(progress) {
      this.$store.dispatch('auth/module/updateProgress', {
        id: this.module.moduleId,
        srcGroupId: this.module.srcGroupId,
        type: this.module.type,
        resourceId: this.module.id,
        progress,
      });
    },
    handleQuizProgress(progress) {
      this.$store.dispatch('auth/module/updateProgress', {
        id: this.module.parent,
        quizId: this.module.id,
        progress: JSON.parse(JSON.stringify(this.module.meta.quiz)),
      });
    },
    handlePdfProgress(progress) {
      this.$store.dispatch('auth/module/updateProgress', {
        id: this.module.moduleId,
        srcGroupId: this.module.srcGroupId,
        type: this.module.type,
        resourceId: this.module.id,
        progress,
      });
    },
  },
  computed: {
    ...mapState({
      module: (s) => s.auth.module.currentResource,
    }),
    isAudio() {
      return this.module && this.module.type === 'PODCAST';
    },
    isPlayerVideo() {
      return this.module && this.module.type === 'VIDEO';
    },
    isQuiz() {
      return this.module && this.module.type === 'QUIZ';
    },
    isPDF() {
      return this.module && this.module.type === 'PDF';
    },
    isFolder() {
      return this.module && this.module.type === 'FOLDER';
    },
  },
  mounted() {
    if (this.module) {
      this.handleModuleChange(this.module);
    }
    window.addEventListener('beforeunload', this.handleUnload);
  },
})
export default class ModulePreview extends Vue {
}
</script>
