<template>
  <div class="audio-player bg-primary-800 ar-16_9 w-full flex relative" :class="{screen: loaded}" v-if="source">
    <div class="audio-thumbnail" v-if="thumbnail" :style="`background-image: url(${thumbnail})`"></div>
    <audio controls crossorigin ref="audio"></audio>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

const plyrScriptUrl = 'https://lodylearn.b-cdn.net/script/plyr/plyr.min.js';

@Component({
  name: 'audio-plyr-player',
  data() {
    return {
      playerId: `AUDIO-${Math.floor((Math.random() * 99999)).toString(16)}-${Date.now()}`,
      player: null,
      loaded: false,
      source: null,
      thumbnail: null,
      ready: false,
      hasScheduleInit: false,
      sourceChanged: false,
      module: null,
    };
  },
  watch: {
    ready(value) {
      if (value && this.hasScheduleInit) {
        this.initialize();
      }
    },
  },
  computed: {},
  methods: {
    setModule(module) {
      this.module = module;
      // if (!this.module.meta) this.module.meta = {};
      // if (!this.module.meta.video) this.module.meta.video = {};
      // this.module.meta.video.time = 15;
    },
    setSource(value) {
      if (value.source && value.source.sound && value.source.sound !== this.source) {
        this.source = value.source.sound;
        this.sourceChanged = true;
      }
      if (value.source && value.source.thumbnail) this.thumbnail = value.source.thumbnail;
    },
    setPlayerTime(time) {
      this.player.currentTime = time;
    },
    getPlayerTime() {
      return this.player.currentTime;
    },
    initialize() {
      if (!this.ready) {
        this.hasScheduleInit = true;
        return;
      }
      if (!this.sourceChanged) return;
      this.loaded = false;
      const { audio } = this.$refs;
      // eslint-disable-next-line no-undef
      this.player = new Plyr(audio, {
        settings: [],
        controls: ['play', 'volume', 'progress', 'current-time'],
      });
      this.player.once('ready', () => {
        this.loaded = true;
        this.player.source = {
          type: 'audio',
          sources: [
            {
              src: this.source,
              type: 'audio/mp3',
            },
          ],
        };
      });
      this.player.on('timeupdate', () => {
        if (!this.module.meta) this.module.meta = {};
        if (!this.module.meta.audio) this.module.meta.audio = {};
        this.module.meta.audio.time = this.getPlayerTime();
        this.module.meta.audio.ended = this.module.meta.audio.ended
            || ((this.player.duration * 0.9) < this.getPlayerTime());
        if (this.module.meta.audio.ended) this.module.checked = true;
      });
      this.player.on('ended', () => {
        this.$emit('progress', this.module.meta);
      });
      this.player.on('pause', () => {
        this.$emit('progress', this.module.meta);
      });
    },
  },
  beforeDestroy() {
    document.head.querySelectorAll('[data-unload]').forEach((item) => { item.remove(); });
  },
  mounted() {
    const script = document.createElement('script');
    script.setAttribute('src', plyrScriptUrl);
    script.setAttribute('data-unload', plyrScriptUrl);
    document.head.appendChild(script);
    script.addEventListener('load', () => {
      this.ready = true;
    });
  },
})
export default class AudioPlyrPlayer extends Vue {
}
</script>

<style scoped>
@import "https://lodylearn.b-cdn.net/script/plyr/plyr.css";

.audio-player:not(.screen) {
  opacity: 0;
}
</style>
