<template>
  <div class="quiz-player-container bg-white w-full ar-16_9 p-8 relative flex flex-col" :class="{inDelay: inDelay}">
    <template v-if="quizEnded">
      <div class="flex flex-col">
        <div
            class="main-question-title bg-primary-900 rounded-3xl text-white text-2xl w-fit py-2 px-8 lg:mt-8 m-auto uppercase">
          Le QUIZ est terminé !
        </div>
        <div class="m-auto mt-16 flex flex-col text-center">
          <div class="text-3xl text-primary-900">Vous avez</div>
          <div class="text-4xl text-primary-500 font-bold my-2">{{ percent }}%</div>
          <div class="text-3xl text-primary-900">de bonnes réponses</div>
        </div>
        <div class="m-auto mt-16">
          <button @click="() => initialize(true)"
                  class="border-2 w-fit mx-0 my-auto flex flex-shrink-0 flex-grow-0 items-center px-6 py-2 rounded-full border-primary-900 transition hover:bg-primary-900 text-primary-900 hover:text-white">
            <span class="m-auto text-2xl">Recommencer</span>
          </button>
        </div>
      </div>
    </template>
    <template v-if="!quizEnded">
      <template v-if="currentQuestion">
        <div class="flex">
          <div
              class="main-question-title bg-primary-900 rounded-3xl text-white text-2xl w-fit px-8 py-2 mt-8 m-auto uppercase">
            {{ currentQuestion.question }}
          </div>
        </div>
        <div class="responses my-6" :key="currentQuestion.question" ref="responseList">
          <quiz-response :color="`tip-${response.color}`" class="m-auto"
                         :is-valid="response.isCorrect"
                         v-for="response in currentQuestion.responses"
                         :key="JSON.stringify(response)"
                         @response="(e) => handleClickResponse(e, response)">
            {{ response.label }}
          </quiz-response>
        </div>
      </template>
      <div class="quiz-navigation flex flex-nowrap m-0 mt-auto" v-if="!inDelay">
        <button @click="prevQuestion"
                :disabled="!hasPreviousIndex"
                :class="{
                'bg-gray-300 cursor-not-allowed': !hasPreviousIndex,
                'bg-primary-500 transition hover:bg-primary-900 text-white': hasPreviousIndex,
              }"
                class="w-fit mx-0 my-auto flex flex-shrink-0 flex-grow-0 items-center px-6 py-2 rounded-full">
          <svg class="my-auto ml-0 mr-4" xmlns="http://www.w3.org/2000/svg" width="6.874" height="12.747"
               viewBox="0 0 6.874 12.747">
            <path id="Path_204" data-name="Path 204" d="M0,5.667,5.667,0l5.667,5.667"
                  transform="translate(0.5 12.04) rotate(-90)" fill="none" stroke="#fff" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="1"/>
          </svg>
          <span class="m-auto">Précédent</span>
        </button>
        <div class="progress-bar bg-gray-300 mx-8 my-auto w-full relative">
          <div class="cursor top-0 bottom-0 rounded-full bg-primary-500 absolute left-0"
               :style="{
              width: `calc(100% / ${questionCount > 0 ? questionCount : 1})`,
              left: `calc(${currentIndex} * calc(100% / ${questionCount > 0 ? questionCount : 1}))`,
             }"
          ></div>
        </div>
        <button @click="nextQuestion"
                v-if="hasNextIndex"
                class="w-fit mx-0 my-auto flex flex-shrink-0 flex-grow-0 items-center px-6 py-2 rounded-full bg-primary-500 transition hover:bg-primary-900 text-white">
          <span class="m-auto">Suivant</span>
          <svg class="my-auto mr-0 ml-4" xmlns="http://www.w3.org/2000/svg" width="6.823" height="12.645"
               viewBox="0 0 6.823 12.645">
            <path id="Path_204" data-name="Path 204" d="M0,5.615,5.615,0l5.615,5.615"
                  transform="translate(6.323 0.707) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="1"/>
          </svg>
        </button>
        <button v-else @click="nextQuestion"
                class="w-fit mx-0 my-auto flex flex-shrink-0 flex-grow-0 items-center px-6 py-2 rounded-full bg-primary-500 transition hover:bg-primary-900 text-white">
          <span class="m-auto">Terminer</span>
          <svg class="my-auto mr-0 ml-4" xmlns="http://www.w3.org/2000/svg" width="6.823" height="12.645"
               viewBox="0 0 6.823 12.645">
            <path id="Path_204" data-name="Path 204" d="M0,5.615,5.615,0l5.615,5.615"
                  transform="translate(6.323 0.707) rotate(90)" fill="none" stroke="#fff" stroke-linecap="round"
                  stroke-linejoin="round" stroke-width="1"/>
          </svg>
        </button>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'quiz-player',
  components: {
    QuizResponse: () => import(/* webpackPrefetch: true */ './quiz-response.vue'),
  },
  data() {
    return {
      module: null,
      responses: {},
      currentIndex: 0,
      tmpDisabled: false,
      ended: false,
      percent: 0,
      inDelay: false,
      // ----
      // global behaviors
      // ----
      // if checked then wrong responses are not allowed and the response is going to be shaky before skip
      shakeIfErrorBeforeNext: true,
      nextAfterDelayEventIfErrored: true,
      skipDelay: 1500,
      // if checked then the percent is go check subresponse / total subresponse
      // else total good answered question / total question
      percentIsBasedOnTotalCheckedResponses: false,
    };
  },
  computed: {
    currentQuestion() {
      if (!this.module || !this.module.quiz || this.module.quiz[this.currentIndex] === undefined) return null;
      // console.log(this.module.quiz[this.currentIndex]);
      return this.module.quiz[this.currentIndex];
    },
    questionCount() {
      if (!this.module || !this.module.quiz) return 0;
      return this.module.quiz.length;
    },
    hasNextIndex() {
      return this.currentIndex < (this.questionCount - 1);
    },
    hasPreviousIndex() {
      return this.currentIndex > 0;
    },
    quizEnded() {
      return this.ended;
    },
    quizType() {
      if (!this.module || !this.module.quiz || this.module.quiz[this.currentIndex] === undefined) return null;
      // console.log(this.module.quiz[this.currentIndex].upstream);
      return this.module.quiz[this.currentIndex].upstream;
    },
  },
  watch: {
    currentIndex(value) {
      this.handleCurrentIndex(value);
    },
  },
  methods: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    handleClickResponse() {},
    handleCurrentIndex(value) {
      this.$nextTick(() => {
        if (!this.$refs.responseList) return;
        if (this.responses[value] === undefined) return;
        // eslint-disable-next-line no-underscore-dangle
        const components = Array.from(this.$refs.responseList.children).map((el) => el.__vue__);
        for (let i = 0; i < components.length; i += 1) {
          components[i].setValidationMode(this.responses[value][i].validationMode);
          // eslint-disable-next-line no-continue
          if (this.responses[value][i] === undefined) continue;
          if (this.responses[value][i].checked) {
            components[i].check();
            // eslint-disable-next-line no-continue
            continue;
          }
          components[i].uncheck();
        }
      });
    },
    gotoEndPage() {
      let percent = 0;
      if (this.percentIsBasedOnTotalCheckedResponses) {
        const total = Object.keys(this.responses).reduce((tmp, key) => (tmp + this.responses[key].reduce((tt, item) => (tt + (item.checked === item.shouldBeChecked ? 1 : 0)), 0)), 0);
        const responseCount = Object.keys(this.responses).reduce((tmp, key) => (tmp + this.responses[key].length), 0);
        percent = (total * 100) / responseCount;
      } else {
        const total = Object.keys(this.responses).reduce((tmp, key) => {
          const isCorrect = this.responses[key].length > 0 && this.responses[key].reduce((tt, item) => (tt && item.checked === item.shouldBeChecked), true);
          return tmp + (isCorrect ? 1 : 0);
        }, 0);
        const responseCount = Object.keys(this.responses).length;
        percent = (total * 100) / responseCount;
      }
      this.percent = Math.round(percent);
      this.ended = true;
      this.commitToModule();
      this.$emit('quiz-end');
    },
    prevQuestion() {
      if (!this.hasPreviousIndex) return;
      this.currentIndex -= 1;
      this.commitToModule();
    },
    nextQuestion() {
      if (!this.$refs.responseList) return;
      // eslint-disable-next-line no-underscore-dangle
      const components = Array.from(this.$refs.responseList.children).map((el) => el.__vue__);
      let isAtLeastOneNotValidationMode = false;
      const data = components.map((c) => {
        if (c.getFormData().validationMode) {
          isAtLeastOneNotValidationMode = true;
        }
        c.setValidationMode(true);
        return c.getFormData();
      });

      if (isAtLeastOneNotValidationMode) {
        this.responses[this.currentIndex] = data;
        this.forceNext();
        return;
      }

      if (this.shakeIfErrorBeforeNext) {
        let errored = false;
        // eslint-disable-next-line no-restricted-syntax
        for (const component of components) {
          const itemData = component.getFormData();
          if (itemData.checked !== itemData.shouldBeChecked) {
            component.shake();
            errored = true;
          }
        }
        if (errored) {
          if (!this.nextAfterDelayEventIfErrored) return;
          this.responses[this.currentIndex] = data;
          this.delayForceNext(this.skipDelay);
          return;
        }
      }
      this.responses[this.currentIndex] = data;
      if (this.nextAfterDelayEventIfErrored) {
        this.delayForceNext(this.skipDelay);
        return;
      }
      this.forceNext();
    },
    delayForceNext(time) {
      this.inDelay = true;
      setTimeout(() => this.forceNext(), time);
    },
    forceNext() {
      this.inDelay = false;
      if (!this.hasNextIndex) {
        this.gotoEndPage();
        return;
      }
      // return;
      this.currentIndex += 1;
      this.commitToModule();
    },
    setSource(module) {
      if (this.module && this.module.id === module.id) {
        return;
      }
      this.module = module;
      this.initialize();
    },
    commitToModule() {
      if (!this.module.meta) this.module.meta = {};
      if (!this.module.meta.quiz) this.module.meta.quiz = {};
      this.module.meta.quiz.responses = this.responses;
      this.module.meta.quiz.currentIndex = this.currentIndex;
      this.module.meta.quiz.tmpDisabled = this.tmpDisabled;
      this.module.meta.quiz.upstream = this.quizType;
      this.module.meta.quiz.ended = this.ended;
      this.module.meta.quiz.percent = this.percent;
      this.$emit('progress', this.module.meta.quiz);
    },
    initialize(hardReset = false) {
      this.responses = {};
      this.currentIndex = -1;
      this.tmpDisabled = false;
      this.upstream = this.quizType;
      this.inDelay = false;
      this.ended = false;
      this.percent = 0;
      if (!this.module) {
        return;
      }
      this.currentIndex = 0;
      if (!hardReset && this.module.meta && this.module.meta.quiz) {
        this.responses = this.module.meta.quiz.responses;
        this.currentIndex = this.module.meta.quiz.currentIndex;
        this.tmpDisabled = this.module.meta.quiz.tmpDisabled;
        this.upstream = this.module.meta.quiz.upstream;
        this.ended = this.module.meta.quiz.ended;
        this.percent = this.module.meta.quiz.percent;
        this.handleCurrentIndex(this.currentIndex);
      }
      this.commitToModule();
    },
  },
})
export default class QuizPlayer extends Vue {
}
</script>

<style scoped>

.w-144px {
  width: 144px;
  height: 144px;
}

.progress-bar {
  height: 8px;
  border-radius: 4px;
}
@keyframes loads {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}
.inDelay::before {
  content: '';
  position: absolute;
  top: 16px;
  left: 54px;
  right: 54px;
  height: 8px;
  background: rgb(22, 34, 58);
  border-radius: 8px;
  transform-origin: left center;
  animation: loads 1500ms linear forwards;
}
</style>
