<template>
  <div class="video-player-container" :class="{screen: loaded}" v-if="source && thumbnail">
    <video controls crossorigin playsinline ref="video" :data-poster="thumbnail">
      <track kind="captions" label="French" srclang="fr" :src="subtitles" v-if="subtitles" default>
    </video>
  </div>
</template>

<script>
import Vue from 'vue';
import Hls from 'hls.js';
import { Component } from 'vue-property-decorator';

const plyrScriptUrl = 'https://lodylearn.b-cdn.net/script/plyr/plyr.min.js';

@Component({
  name: 'plyr-player',
  data() {
    return {
      playerId: `VIDEO-${Math.floor((Math.random() * 99999))
        .toString(16)}-${Date.now()}`,
      player: null,
      nextInitTime: 0,
      loaded: false,
      source: null,
      thumbnail: null,
      subtitles: null,
      lastChapter: null,
      chapters: [],
      ready: false,
      hasScheduleInit: false,
      sourceChanged: false,
      module: null,
      hls: null,
      defaultPlyrOptions: {
        debug: false,
        controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'airplay', 'fullscreen'],
        settings: ['captions', 'quality', 'speed', 'loop'],
      },
    };
  },
  watch: {
    ready(value) {
      if (value && this.hasScheduleInit) {
        this.initialize();
      }
    },
  },
  computed: {},
  methods: {
    setModule(module) {
      this.module = module;
      // if (!this.module.meta) this.module.meta = {};
      // if (!this.module.meta.video) this.module.meta.video = {};
      // this.module.meta.video.time = 15;
    },
    setSource(value) {
      // if (this.player) this.player.destroy();
      if (value.m3u8 && value.m3u8 !== this.source) {
        this.source = value.m3u8;
        this.sourceChanged = true;
      }
      if (value.thumbnail) this.thumbnail = value.thumbnail;
      if (value.chapters) this.chapters = value.chapters;
      if (value.subtitles) this.subtitles = value.subtitles;
    },
    setChapter(index, useCallbacks = true) {
      if (!this.player) return;
      const goto = Math.min(Math.max(0, index), this.chapters.length - 1);
      this.player.currentTime = this.chapters[goto].start;
      if (useCallbacks) {
        this.$emit('chapter-change', {
          chapter: this.chapters[goto],
          player: this.player,
        });
      }
    },
    /**
     * Ajout d'un delta de 5s pour éviter la latence en changeant de chapitre
     * Ca evite que la video revienne en arrière quand c'est la video qui
     * call le setChapter
     *
     * @param id
     */
    setChapterId(id) {
      const DELTA = 0; // 0 seconds
      const index = this.chapters.map((chapter) => chapter.index).indexOf(id);
      if (index === -1) return;
      const chapter = this.chapters[index];
      this.nextInitTime = chapter.start;
      if (!this.player) return;
      if (Math.abs(this.player.currentTime - chapter.start) > DELTA) {
        if (this.player) {
          this.player.currentTime = chapter.start;
        }
      }
    },
    setPlayerTime(time) {
      this.player.currentTime = time;
    },
    getPlayerTime() {
      return this.player ? this.player.currentTime : 0;
    },
    setUpPlayer() {
      const { video } = this.$refs;
      const callback = () => {
        this.setUpPlayerEvents();
      };
      if (video.canPlayType('application/vnd.apple.mpegurl')) {
        this.setUpVndPlayer(callback);
      } else if (!Hls.isSupported()) {
        this.setUpFallbackPlayer(callback);
      } else {
        this.setUpHlsPlayer(callback);
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setUpHlsPlayer(callback = () => {}) {
      const { video } = this.$refs;
      if (this.hls) this.hls.destroy();
      this.hls = new Hls();
      const defaultOptions = { ...this.defaultPlyrOptions };
      this.hls.loadSource(this.source);
      this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
        const availableQualities = this.hls.levels.map((l) => l.height);
        if (!this.player) {
          // eslint-disable-next-line no-undef
          this.player = new Plyr(video, defaultOptions);
        }
        this.player.poster = this.thumbnail;
        this.player.quality = {
          default: Math.max(...availableQualities),
          options: availableQualities,
          forced: true,
          onChange: (e) => {
            this.hls.levels.forEach((level, levelIndex) => {
              if (level.height === e) {
                this.hls.currentLevel = levelIndex;
              }
            });
          },
        };
        callback();
        this.loaded = true;
      });
      this.hls.attachMedia(video);
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setUpVndPlayer(callback = () => {}) {
      this.setUpFallbackPlayer(callback);
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setUpFallbackPlayer(callback = () => {}) {
      this.loaded = true;
      const { video } = this.$refs;
      // eslint-disable-next-line no-undef
      this.player = new Plyr(video, this.defaultPlyrOptions);
      video.src = this.source;
      this.player.once('canplay', () => {
        this.loaded = true;
      });
      callback();
    },
    setUpPlayerEvents() {
      this.player.once('canplay', () => {
        if (this.nextInitTime > 0) {
          this.setPlayerTime(this.nextInitTime);
          this.nextInitTime = 0;
        } else if (this.module.meta && this.module.meta.video && this.module.meta.video.time) {
          this.setPlayerTime(this.module.meta.video.time);
        }
        this.player.markers = {
          enabled: true,
          points: this.chapters.map((chapter) => ({
            time: chapter.start,
            tip: chapter.title,
          })),
        };
      });
      this.player.on('timeupdate', () => {
        if (!this.module.meta) this.module.meta = {};
        if (!this.module.meta.video) this.module.meta.video = {};
        this.module.meta.video.time = this.getPlayerTime();
        if (this.chapters.length > 0) {
          let tmp = null;
          // eslint-disable-next-line no-restricted-syntax
          for (const chapter of this.chapters) {
            // eslint-disable-next-line no-continue
            if (chapter.start > this.player.currentTime) continue;
            // eslint-disable-next-line no-continue
            if (chapter.end < this.player.currentTime) continue;
            tmp = chapter;
          }
          if (tmp !== this.lastChapter) {
            this.$emit('chapter-change', {
              chapter: tmp,
              player: this.player,
            });
            this.lastChapter = tmp;
          }
        }
      });
    },
    initialize() {
      if (!this.ready) {
        this.hasScheduleInit = true;
        return;
      }
      if (!this.sourceChanged) return;
      this.sourceChanged = false;
      this.loaded = false;
      this.setUpPlayer();
    },
  },
  beforeDestroy() {
    document.head.querySelectorAll('[data-unload]').forEach((item) => {
      item.remove();
    });
  },
  mounted() {
    const script = document.createElement('script');
    script.setAttribute('src', plyrScriptUrl);
    script.setAttribute('data-unload', plyrScriptUrl);
    document.head.appendChild(script);
    script.addEventListener('load', () => {
      this.ready = true;
    });
  },
})
export default class PlyrPlayer extends Vue {
}
</script>

<style scoped>
@import "https://lodylearn.b-cdn.net/script/plyr/plyr.css";
.video-player-container:not(.screen) {
  opacity: 0;
}
</style>
